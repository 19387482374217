import React from 'react'
import Bredcm from '../Menhome/breadcrumb'
import First from '../ServisesecMen/First'
function Main() {
  return (
    <>
          <Bredcm
            subtitle="Services Deatils"
            title="Home"
            subtitledown="Services Deatils" />

            <First/>
    </>
  )
}

export default Main