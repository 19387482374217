import React from "react";
import Bredcm from "../Menhome/breadcrumb";
import FotoTecnicas from "../../assets/img/features/fotocasal.jpg";
import FotoTecnica from "../../assets/img/features/implante-tecnicas.jpg";
import ImplanteUnico from "../../assets/img/features/implante-unico.jpg";
import CargaImediata from "../../assets/img/features/carga-imediata.jpg";
import ImplanteDuplo from "../../assets/img/features/implante-duplo.jpg";
import FixarDentadura from "../../assets/img/features/fixar-dentadura.jpg";
import ArcadaDentaria from "../../assets/img/features/arcada-dentaria.jpg";
import Zigomatico from "../../assets/img/features/zigomatico.jpg";
import About from "../Menabout/About";
import Appointment from "../Menhome/Appointment";
import Ourdr from "../Menhome/Ourdr";
import Faq from "../Menhome/Faqsection";
import Blog from "../Menhome/Blog";

function Main() {
	return (
		<>
			<Bredcm
				subtitle="Implantes"
				title="Home"
				subtitledown="E VOLTE A SORRIR COM AUTOESTIMA E CONFIANÇA. "
			/>

			<section className="about-area about-p pt-120 pb-120 p-relative fix">
				<div className="container">
					<div
						className="row justify-content-center align-items-center"
						style={{ textAlign: "center" }}
					>
						<div className="col-lg-6 col-md-12 col-sm-12">
							<div
								className="about-content s-about-content  wow fadeInRight  animated"
								data-animation="fadeInRight"
								data-delay=".4s"
							>
								<div className="about-title second-title pb-25">
									<h3>SEUS DENTES FIXOS COM IMPLANTES DENTÁRIOS</h3>
								</div>
								<b>
									<ul>
										<li>Melhora a mastigação e o paladar...</li>
										<li>É seguro e tranquilo...</li>
										<li>Melhora a fonética...</li>
										<li>Possibilita a estabilidade da arcada dentária...</li>
										<li>Beneficia as relações interpessoais...</li>
										<li>Conserva os dentes naturais...</li>
										<li>Evita a perda óssea...</li>
										<li>Promove estética e autoestima...</li>
									</ul>
								</b>

								<b>
									<img
										className="imgens-implantes"
										src={FotoTecnicas}
										alt="img"
									/>

									<h3>Técnicas</h3>
									<div className="col-md-6 col-md-12 col-sm-12"></div>
									<p>
										A implantodontia se destaca por uma diversidade de técnicas
										que colaboram para a saúde bucal e para um sorriso
										impecável.
									</p>
								</b>

								<b>
									<h3>TÉCNICA DE IMPLANTE ÚNICO FIXO</h3>
									<div className="col-md-6 col-md-12 col-sm-12">
										<img
											className="imgens-implantes"
											src={FotoTecnica}
											alt="img"
										/>
										<p>
											O processo do implante fixo unitário é, de fato, o mais
											procurado pelas pessoas. A técnica consiste em colocar o
											parafuso do titânio para substituir o dente que foi
											perdido. A colocação é, de modo geral, realizada meses
											posteriores à perda do dente.
										</p>
										<p>
											Coroa cerâmica ou próteses são postas dentro de dois a
											três meses posteriores. Ao longo desse período, ocorre o
											que denominamos de osseointegração.
										</p>
										<p>Técnica de implante único a partir de extração</p>
										<p>
											Neste processo, o implante é colocado posteriormente após
											a extração imediata do dente. Tal estratégia corresponde a
											substituir o dente por um implante. O implante é posto
											meses posteriores ao prazo de osseointegração. Ao longo
											deste tempo, é comum que se utilize próteses removíveis de
											teor provisório.
										</p>
									</div>
								</b>

								<b>
									<h3>
										CARGA IMEDIATA – IMPLANTE ÚNICO E IMEDIATO FIXADO + PRÓTESE
									</h3>
									<img
										className="imgens-implantes"
										src={ImplanteUnico}
										alt="img"
									/>
									<p>
										Nesse procedimento é comum que consiga realizar a extração
										do dente, na mesma data ou aguardar semanas posteriores à
										extração e fazer a colocação do implante. É colocado o
										implante dentário na mesma altura em que há a fixação de
										coroa definitiva ou provisória.
									</p>
									<p>
										É a denominada “carga imediata”. Este procedimento é dotado
										de conclusão mais rápida. As vantagens relativas ao teor
										estético são ainda mais aprimoradas.
									</p>
									<p>
										A denominada carga imediata age permitindo a entrada na
										clínica, sem o elemento ósseo e saída, na mesma data, com um
										dente fixado a partir do implante. Contudo, nem todos são
										candidatos a realizar esta técnica
									</p>
								</b>

								<b>
									<h3>
										TÉCNICA DE IMPLANTE DUPLO: PREENCHIMENTO DE AUSÊNCIA DE 3
										DENTES
									</h3>
									<img
										className="imgens-implantes"
										src={CargaImediata}
										alt="img"
									/>
									<p>
										Este processo se assemelha ao anterior. Com a possibilidade
										de realizar extração e pôr os implantes na mesma data, ou ao
										oposto, realizando extrações e dispor os implantes dentro de
										semanas posteriores, ao passo que ocorre a regeneração do
										osso.
									</p>
									<p>
										De outro modo, conforme tenha a apresentação de três dentes
										ausentes e, após a análise das condições ósseas adequadas,
										pode-se realizar dois implantes. O que diferencia é a
										possibilidade de fazer a colocação de dois implantes,
										permanecendo um espaçamento vazio intermediário. Em ambos
										implantes, encaixa-se a ponte cerâmica em três elementos. Há
										uma coroa que se situa em apoio na região gengival.
									</p>
									<p>
										O processo reduz custos de tratamentos e age permitindo o
										preenchimento da ausência do quantitativo de três dentes.
									</p>
								</b>

								<b>
									<h3>IMPLANTE PARA FIXAR DENTADURA – “OVERDENTURE”</h3>
									<img
										className="imgens-implantes"
										src={ImplanteDuplo}
										alt="img"
									/>
									<p>
										O processo é realizado a partir da colocação de dois
										implantes na região da mandíbula, a fim de fazer a fixação
										da prótese de dentes acrílicos. Para que ocorra o encaixe da
										prótese, faz-se o uso do sistema que é composto por
										extremidades em formato de bola. Esta vai fazer o encaixe a
										partir de um “o-ring” posto diretamente no elemento
										acrílico.
									</p>
									<p>
										O procedimento constrói um dispositivo estável. A prótese
										permanece fixada repousando em cima da gengiva sem
										oscilações.
									</p>
								</b>

								<b>
									<h3>
										ARCADA DENTÁRIA TOTAL EM QUATRO IMPLANTES – ALL-ON-FOUR
									</h3>
									<img
										className="imgens-implantes"
										src={ArcadaDentaria}
										alt="img"
									/>
									<p>
										O procedimento de ambos os dentes em quatro implantes teve
										invenção para a reabilitação de edêntulos na região da
										maxila. Trata-se de um processo de intervenção cirúrgica que
										age permitindo que se coloque quatro implantes para a
										fixação de todos os elementos ósseos – dentes.
									</p>
									<p>
										As próteses em cima de implantes são constituídas, de modo
										geral, por 12 dentes e têm o aparafusamento dos implantes.
									</p>
									<p>
										Esta prótese tem sua fixação aos implantes e é revestida de
										cerâmica ou produto metal acrílico. Este é composto por
										barra em metal com quatro buracos, onde os implantes são
										parafusados. É um elemento fixo, com estabilidade e parece
										muito com os dentes de forma natural.
									</p>
								</b>
								<b>
									<h3>
										DENTES EM TOTALIDADE A PARTIR DE SEIS IMPLANTES – ALL-ON-SIX
									</h3>
									<img
										className="imgens-implantes"
										src={FixarDentadura}
										alt="img"
									/>
									<p>
										O processo é semelhante ao anterior. Porém, com a quantidade
										de seis implantes para toda a arcada dentária. A
										recomendação é para aqueles que não tenham todos os dentes
										da maxila superior. Dessa forma, o alcance de fixação é
										aprimorado.
									</p>
								</b>

								<b>
									<h3>IMPLANTE ZIGOMÁTICO</h3>
									<p>
										O implante fixo zigomático trata-se de um elemento
										diferencial dos tradicionais em virtude do teor fixo ao osso
										denominado como “maçã da face”. Teve desenvolvimento como
										uma opção para tratar pacientes que disponibilizam atrofia
										maxilar rígida em substituição aos enxertos ósseos.
									</p>
									<p>
										O intuito é reabilitar a funcionalidade e teor estético por
										meio de prótese fixa instalada superior, em substituição de
										próteses que abrangem a totalidade e são removíveis.
									</p>
									<p>
										O elemento fixo zigomático apresenta uma outra opção para
										reabilitar a estética, mastigação, funcionalidade e a
										socialização de pacientes que dispõem de atrofia severa
									</p>
								</b>

								<h3>Quais as principais etapas do implante dentário?</h3>
								<b>Diagnóstico do paciente</b>
								<p>
									A primeira etapa é examinar o paciente, seu histórico de saúde
									e avaliar se ele tem estrutura óssea adequada para suportar o
									implante dentário.
								</p>
								<b>Intervenção cirúrgica</b>
								<p>
									É uma cirurgia realizada no consultório do dentista em que o
									paciente recebe anestesia local para ficar confortável durante
									o procedimento é necessário aguardar de 3 a 6 meses para que
									ocorra a osseointegração. O paciente retorna ao consultório e
									faz uma radiografia para saber se está tudo certo. Com o osso
									integrado, já é possível dar continuidade às próximas etapas
									do implante dentário.
								</p>
								<b>Elaboração da prótese</b>
								<p>
									Na fase protética, é o momento de escolher a cor da coroa,
									fazer moldagem e enviá-la para a confecção em laboratório. A
									peça, feita de cerâmica, deve estar compatível com a mordida.
								</p>
								<b>Colocação da prótese</b>
								<p>
									É feito um procedimento cirúrgico em que o dentista reabre a
									região em que foi colocado o pino de titânio para que ele
									possa receber a prótese.
								</p>
								<b>Acompanhamento e cuidados essenciais</b>
								<ul>
									O paciente deve seguir algumas recomendações no pós-operatório
									para que a cicatrização ocorra sem problemas e a recuperação
									seja mais rápida. Desse modo, é essencial:
									<li>• Tomar a medicação prescrita;</li>
									<li>• Utilizar bolsas de gelo para reduzir o inchaço;</li>
									<li>• Fazer repouso no dia da cirurgia;</li>
									<li>
										• Adotar uma alimentação líquida ou pastosa nos primeiros
										dias;
									</li>
									<li style={{ marginLeft: "-20px" }}>
										• Realizar a limpeza bucal com uma escova de cerdas macias e
										fio dental, tendo cuidado com a região operada;
									</li>
									<li style={{ marginLeft: "-20px" }}>
										• Fazer o acompanhamento odontológico regular para avaliar
										as condições de saúde bucal e do implante.
									</li>
								</ul>
								<p>
									O dispositivo precisa de um meio bucal saudável, por isso o
									paciente deve ser disciplinado com a higienização. Quem não
									faz a limpeza bucal adequada terá problemas, porque, se osso
									inflamar, poderá perder o implante dentário.
								</p>
							</div>
						</div>
					</div>
					<div className="slider-btn mt-20">
									<a  style={{left: "1px", background: "#1da551", marginLeft: "567px"}} target="blank" href="https://api.whatsapp.com/send?phone=5561996221835&text=Ol%C3%A1%20gostaria%20de%20agendar%20uma%20consulta%20de%20Implante%20Dent%C3%A1rio%20na%20Cl%C3%ADnica%20Neoident" className="btn ss-btn smoth-scroll">
										Agende sua consulta
									</a>
								</div>
				</div>
			</section>
		</>
	);
}

export default Main;
