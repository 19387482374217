import React, { useState } from "react";
import Logo from "../../assets/img/logo/logo.png";
import { Link } from "react-router-dom";


function Main() {
	const [mobile, setmobile] = useState(false);
	const [home, setHome] = useState(false);
	const [Services, setServices] = useState(false);
	const [Pages, setPages] = useState(false);
	const [Blog, setBlog] = useState(false);

	return (
		<>



			<header className="header-area header-three">
				<div className="header-top second-header d-md-block headerzin">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-8 col-md-8 d-none d-lg-block">
								<div className="header-cta">
									<ul>
										<li>
											<i className="icon fal fa-envelope" />
											<a
												target="blank"
												href="https://www.google.com/maps/place/Clínica+Neoident+Odontologia/@-15.8004461,-47.929748,17z/data=!4m6!3m5!1s0x935a30776b955555:0x755b708c94b1714c!8m2!3d-15.8004461!4d-47.9252419!16s%2Fg%2F11b6j7nk5c?entry=ttu"
											>
												<span>
													CLSW 303 Bloco A Sala 115 - Ed. Atlantar Center
													Sudoeste
												</span>
											</a>
										</li>
										<li>
											<i className="icon fal fa-phone" />
											<a href="https://api.whatsapp.com/send?phone=5561996221835&text=Ol%C3%A1!%20Vi%20o%20trabalho%20de%20voc%C3%AAs%20pelo%20site%20e%20gostaria%20de%20agendar%20uma%20consulta%20na%20Cli%CC%81nica%20Neoident." target="blank">
												<span>(61) 99622-1835</span>
											</a>
										</li>
									</ul>
								</div>
							</div>
							<div className="col-lg-4 col-md-4 d-none d-lg-block text-right">
								<div className="header-social">
									<span>
										<a href="https://www.facebook.com/odontologianeoident/">
											<i className="fab fa-facebook-f" />
										</a>
										<a href="https://www.instagram.com/clinica_neoidentbrasilia/?hl=en">
											<i className="fab fa-instagram" />
										</a>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div
					id="header-sticky"
					className="menu-area sticky-menu"
					style={{ backgroundColor: "white", marginTop: "56px" }}
				>
					<div className="container">
						<div className="second-menu">
							<div className="row align-items-center">
								<div className="col-xl-2 col-lg-2">
									<div className="logo">
										<Link to="/">
											<img src={Logo} alt="logo" />
										</Link>
									</div>
								</div>
								<div className="col-xl-7 col-lg-7">
									<div className="main-menu text-right text-xl-right">
										<nav id="mobile-menu">
											<ul>
												<li className="has-sub">
													<Link to="/">Home</Link>
												</li>
												<li>
													<Link to="sobre">Quem Somos</Link>
												</li>
												<li className="has-sub">
													<a href="#service-details2">Serviços</a>
												</li>
												<li className="has-sub">
													<a href="#pricing">Planos</a>
												</li>
											</ul>
										</nav>
									</div>
								</div>
								<div className="col-xl-3 col-lg-3 text-right d-none d-lg-block mt-25 mb-25 text-right text-xl-right">
									<div className="login">
										<ul>
											<li></li>
										</ul>
									</div>
								</div>
								<div className="col-12">
									<div className="mobile-menu mean-container">
										<div className="mean-bar">
											<Link
												to="#"
												onClick={() => setmobile(!mobile)}
												className={`meanmenu-reveal ${mobile && "meanclose"}`}
												style={{
													right: "0px",
													left: "auto",
													textAlign: "center",
													textIndent: "0px",
													fontSize: "18px",
												}}
											>
												{mobile ? (
													"X"
												) : (
													<span>
														<span>
															<span></span>
														</span>
													</span>
												)}
											</Link>
											{mobile && (
												<nav className="mean-nav">
													<ul>
														<li className="has-sub">
															<Link to="#"> Home</Link>

															<Link
																className={`mean-expand ${
																	mobile && "mean-clicked"
																}`}
																to="#"
																onClick={() => {
																	setHome(!home);
																}}
																style={{ fontSize: 18 }}
															>
																{home ? "-" : "+"}
															</Link>
														</li>
														<li>
															<Link to="#">Quem Somos</Link>
														</li>
														<li className="has-sub">
															<Link to="#">Especialidades</Link>
															<Link
																className={`mean-expand ${
																	mobile && "mean-clicked"
																}`}
																to="#"
																onClick={() => {
																	setServices(!Services);
																}}
																style={{ fontSize: 18 }}
															>
																{Services ? "-" : "+"}
															</Link>
														</li>
														<li className="has-sub">
															<Link to="#">Galeria</Link>
															<Link
																className={`mean-expand ${
																	Pages && "mean-clicked"
																}`}
																to="#"
																style={{ fontSize: 18 }}
																onClick={() => {
																	setPages(!Pages);
																}}
															>
																{Pages ? "-" : "+"}
															</Link>
														</li>
														<li className="has-sub">
															<Link to="#">Convênio</Link>

															<Link
																className={`mean-expand ${
																	Blog && "mean-clicked"
																}`}
																to="#"
																style={{ fontSize: 18 }}
																onClick={() => {
																	setBlog(!Blog);
																}}
															>
																+
															</Link>
														</li>
														<li className="mean-last">
															<Link to="#">Contato </Link>
														</li>
													</ul>
												</nav>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>
		</>
	);
}

export default Main;
