import React from "react";
import Bredcm from "../Menhome/breadcrumb";
import Odontopediatriafoto from "../../assets/img/features/odontopediatriafoto.png"
import About from "../Menabout/About";
import Appointment from "../Menhome/Appointment";
import Ourdr from "../Menhome/Ourdr";
import Faq from "../Menhome/Faqsection";
import Blog from "../Menhome/Blog";

function Main() {
	return (
		<>
			<Bredcm subtitle="Odontopediatria" title="Home" subtitledown="Odontopediatria" />

			<section className="about-area about-p pt-120 pb-120 p-relative fix">
				<div className="container">
					<div className="row justify-content-center align-items-center">
						<div className="col-lg-6 col-md-12 col-sm-12">
							<div
								className="s-about-img p-relative  wow fadeInLeft animated"
								data-animation="fadeInLeft"
								data-delay=".4s"
							>
								<img src={Odontopediatriafoto} alt="img" />
							</div>
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12">
							<div
								className="about-content s-about-content  wow fadeInRight  animated"
								data-animation="fadeInRight"
								data-delay=".4s"
							>
								<div className="about-title second-title pb-25">
									<h5>Nossos pequeninos também precisam de atendimento especial!</h5>
								</div>
								<p>
								 Área da odontologia que cuida da saúde bucal das crianças,
                 desde o momento do nascimento até a adolescência. 
                Além disso, os dentistas são responsáveis também pela higiene bucal dos bebês e das gestantes.
								</p>
								
								<p>
								O atendimento a crianças nessa área dedica-se a saúde oral em bebês, crianças e adolescentes, 
                e tem como objetivo principal a realização de tratamentos preventivos, mas também curativos,
                 para que a criança passe pelo período da adolescência 
                e atinja a idade adulta com uma boca saudável, estética e funcional.
								</p>
								<p>
								 Se torna importante pelo acompanhamento da formação dentária a partir dos primeiros anos de vida da criança. 
                Além de possuir uma relação muito próxima com a ortodontia, que cuida do alinhamento dos dentes do paciente.
								</p>
								<p>É importante que o odontopediatra ensine os cuidados necessários com o dente da criança, apontando o que é mito e o que é verdade para os pais. Assim, um odontopediatra
                   atua também na prevenção de problemas que podem surgir a partir da falta de cuidados 
                   nesses primeiros anos de vida.</p>
									

							</div>
						</div>
					</div>
					<div className="slider-btn mt-20">
									<a  style={{left: "1px", background: "#1da551", marginLeft: "567px"}} target="blank" href="https://api.whatsapp.com/send?phone=5561996221835&text=Ol%C3%A1%20gostaria%20de%20agendar%20uma%20consulta%20de%20Implante%20dent%C3%A1rio%20na%20Cl%C3%ADnica%20Neoident!" className="btn ss-btn smoth-scroll">
										Agende sua consulta
									</a>
								</div>
				</div>
			</section>
		</>
	);
}

export default Main;
