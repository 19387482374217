import React from "react";
import TestimonialAvtOne from "../../assets/img/testimonial/testmonial1.png";
import TestimonialAvtFour from "../../assets/img/testimonial/testmonial4.png";
import TestimonialAvtTwo from "../../assets/img/testimonial/testmonial2.png";
import ReviewIconOne from "../../assets/img/testimonial/review-icon.png";
import TestimonialIconTwo from "../../assets/img/testimonial/qt-icon.png";
import Iconslider from "../../assets/img/icon/pr-icon1.png";
import IconSlidertwo from "../../assets/img/icon/pr-icon2.png";
import IconSliderthree from "../../assets/img/icon/pr-icon3.png";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import TestimonialAvtThree from "../../assets/img/testimonial/testmonial3.png";
import Pipipipopopo from "../../assets/img/testimonial/testmoial5.png";
import Popopopipipi from "../../assets/img/testimonial/testmonial6.png";
import meupipi from "../../assets/img/testimonial/unnamed.png";
import noseupopo from "../../assets/img/testimonial/tete.png";
import cocozinho from "../../assets/img/testimonial/celininha.png";
import buticodomiguel from "../../assets/img/testimonial/celininha.png";
import LohanaCaradeBanana from "../../assets/img/testimonial/lohana.png";
import MileneQuerosene from "../../assets/img/testimonial/milene.png";
import MariannaNascadeBacana from "../../assets/img/testimonial/marianna.png";
import AnaCeciliaCorpodeLombriga from "../../assets/img/testimonial/anacecilia.png";
import AmandaGarotadePrograma from "../../assets/img/testimonial/amanda.png";
import SuemioReumaticoAnemico from "../../assets/img/testimonial/suemio.png";

function Testimonialslider() {
	let satting = {
		dots: true,
		infinite: true,
		arrows: false,
		speed: 1000,
		slidesToShow: 2,
		slidesToScroll: 2,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};
	return (
		<>
			<section
				className="testimonial-area pb-115 p-relative fix"
				style={{ backgroundColor: "#f6f8fc"}} 
			>
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div
								className="section-title text-center mb-50 wow fadeInDown animated"
								data-animation="fadeInDown"
								data-delay=".4s"
							>
								<h2>O que nossos clientes falam de nós?</h2>
							</div>
						</div>
						<div className="col-lg-12">
							<Slider className="testimonial-active" {...satting}>
								<div className="single-testimonial">
									<div className="testi-author">
										<img src={TestimonialAvtTwo} alt="img" />
									</div>
									<div className="review mb-30">
										<img src={ReviewIconOne} alt="img" />
									</div>
									<p>
									“A equipe toda é muito boa e atenciosa, a começar da Keila na recepção.
										 Eu e minha esposa fizemos tratamento lá, inclusive fiz um canal, e gostamos bastante. As doutoras Michele,
										 Evelyn e Mariana que nos atenderam e foram muito solícitas e atenciosas. Recomendo a todos.”

										
									</p>
									<div className="ta-info">
										<h6> Marcos Passarela</h6>
										
									</div>
									<div className="qt-img" >
										<img src={TestimonialIconTwo} alt="img" style={{marginTop:"-25px"}} />
									</div>
								</div>
								<div className="single-testimonial testmonials1">
									<div className="testi-author">
										<img src={TestimonialAvtFour} alt="img" />
									</div>
									<div className="review mb-30">
										<img src={ReviewIconOne} alt="img" />
									</div>
									<p>
									“Eu e minha filha fomos muito bem atendidos pela Dra. Michele. 
                                        Só temos elogios à qualidade do serviço prestado. Recomendo fortemente.”

									</p>
									<div className="ta-info">
										<h6> Luciano Ribeiro</h6>
										
									</div>
									<div className="qt-img">
										<img src={TestimonialIconTwo} alt="img" />
									</div>
								</div>
								<div className="single-testimonial" style={{padding: "90px"}}>
									<div className="testi-author">
										<img src={Pipipipopopo} alt="img" />
									</div> 
									<div className="review mb-30">
										<img src={ReviewIconOne} alt="img" />
									</div>
									<p>
									“Excelente atendimento da Dra. Michele.
										Sempre sou muito bem atendida pela recepcionista.
 										O retorno no whats é sempre rápido. Super indico a clínica e a profissional Michele”
                       </p>
									<div className="ta-info">
										<h6> Fran Vasconcelos</h6>
									
									</div>
									<div className="qt-img">
										<img src={TestimonialIconTwo} alt="img" style={{marginTop:"-23px"}} />
									</div>
								</div>
								<div className="single-testimonial testmonials1">
									<div className="testi-author">
										<img src={Popopopipipi} alt="img" />
									</div>
									<div className="review mb-30">
										<img src={ReviewIconOne} alt="img" />
									</div>
									<p>
									“Doutora super atenciosa, mãos leves e zero dor, super indico.”
									</p>
									<div className="ta-info">
										<h6>Henrique alves</h6>
										
									</div>
									<div className="qt-img">
										<img src={TestimonialIconTwo} alt="img" />
									</div>
								</div>

								<div className="single-testimonial testmonials1">
									<div className="testi-author">
										<img src={TestimonialAvtOne} alt="img" />
									</div>
									<div className="review mb-30">
										<img src={ReviewIconOne} alt="img" />
									</div>
									<p>
									"Fui atendida pela Dra. Mariana, um atendimentos maravilhoso, 
										foram 3 meses de uma novela com um problema que levei até ela de outro dentista,
										 porém ela foi paciente e resolveu com muita atenção e educação"

										
									</p>
									<div className="ta-info">
										<h6>Ingrid Hellen</h6>
										
									</div>
									<div className="qt-img">
										<img src={TestimonialIconTwo} alt="img" />
									</div>
								</div>

								<div className="single-testimonial testmonials2">
									<div className="testi-author">
										<img src={TestimonialAvtThree} alt="img" />
									</div>
									<div className="review mb-30">
										<img src={ReviewIconOne} alt="img" />
									</div>
									<p>
									“Dra. Mariana é excepcional. Mãos de anja!
                     A colaboradora Michele também é um amor.😉 ”

									</p>
									<div className="ta-info">
										<h6> Paulo Moura</h6>
										
									</div>
									<div className="qt-img">
										<img src={TestimonialIconTwo} alt="img" />
									</div>
								</div>

								<div className="single-testimonial testmonials2">
									<div className="testi-author">
										<img src={meupipi} alt="img" />
									</div>
									<div className="review mb-30">
										<img src={ReviewIconOne} alt="img" />
									</div>
									<p>
									“Minha experiência com a Neoident, foi ótima!
                  Um excelente atendimento, profissionais altamente competentes e um ambiente muito agradável.
                 A Dra. Michele tem a mão tão leve que você não sente a agulhada da anestesia,
                 é incrível isso e não sei explicar, simplesmente não senti dor. Rs
                Voltarei em breve para fazer outros procedimentos. ”

									</p>
									<div className="ta-info">
										<h6> Leodete Tavares</h6>
										
									</div>
									<div className="qt-img">
										<img src={TestimonialIconTwo} alt="img" />
									</div>
								</div>

								<div className="single-testimonial testmonials2">
									<div className="testi-author">
										<img src={noseupopo} alt="img" />
									</div>
									<div className="review mb-30">
										<img src={ReviewIconOne} alt="img" />
									</div>
									<p>
									“Super recomendo!! A clínica tem um ambiente muito aconchegante e limpo! O atendimento é maravilhoso,
									 não fiquei aguardando. Fui atendida pela Dra. Michelle Bandeira, que é de uma delicadeza incrível,
									  explicou tudo o que tinha que ser feito de maneira simples e muito clara. O atendimento à noite é muito bom,
										 pois consigo fazer o tratamento sem precisar faltar ao trabalho.
									 Excelentes profissionais!😉👏”

									</p>
									<div className="ta-info">
										<h6>Rawanne Ribeiro</h6>
										
									</div>
									<div className="qt-img">
										<img src={TestimonialIconTwo} alt="img" />
									</div>
								</div>

								<div className="single-testimonial testmonials2">
									<div className="testi-author">
										<img src={LohanaCaradeBanana} alt="img" />
									</div>
									<div className="review mb-30">
										<img src={ReviewIconOne} alt="img" />
									</div>
									<p>
									“Me consultei com a dra. Mariana Lobos para restauração e limpeza e dra. Michele 
									para limpeza e procedimento estético com resina. Achei as duas ótimas, ótimas! 
									A clínica é boa e bem localizada, recebi ótimo atendimento, o trabalho ficou bem feito,
									 me atenderam rápido, me marcaram rápido e achei o valor justo. Por conta de outros tratamentos,
									  mesmo não tendo mais convênio, recebi ótimos descontinhos. Além disso a clínica funciona até 22h,
										 por isso consegui marcar uma emergência no mesmo dia pela noite e não fui cobrada a mais por isso!
									 Confiança e bom atendimento é tudo! Voltarei com certeza. ”

									</p>
									<div className="ta-info">
										<h6> Lohana Gregorim</h6>
										
									</div>
									<div className="qt-img">
										<img src={TestimonialIconTwo} alt="img" />
									</div>
								</div>

								<div className="single-testimonial testmonials2">
									<div className="testi-author">
										<img src={buticodomiguel} alt="img" />
									</div>
									<div className="review mb-30">
										<img src={ReviewIconOne} alt="img" />
									</div>
									<p>
									“Excelente atendimento, desde da recepção pela Keila, até os tratamentos oferecidos,
									 equipe muito competente, em especial Dra. Michele, só tenho a  agradecer o cuidado ,
									 atenção e profissionalismo que dispensam aos seus pacientes . Super indico.”

									</p>
									<div className="ta-info">
										<h6>Celiana Pereira Sacramento</h6>
										
									</div>
									<div className="qt-img">
										<img src={TestimonialIconTwo} alt="img" />
									</div>
								</div>

								<div className="single-testimonial testmonials2">
									<div className="testi-author">
										<img src={MileneQuerosene} alt="img" />
									</div>
									<div className="review mb-30">
										<img src={ReviewIconOne} alt="img" />
									</div>
									<p>
									“Muito profissionais, educados e pacientes. Minha filha amou a Dr.Mariana colocou o aparelho e
									 após 1 ano já retirou ela foi maravilhosa no cuidado, atenção e agilidade
									! Hoje minha filha tem um sorriso perfeito e muito agradecida a toda equipe!! Super recomendo!”

									</p>
									<div className="ta-info">
										<h6>Milene Bianca Couto Cocco</h6>
										
									</div>
									
									<div className="qt-img">
										<img src={TestimonialIconTwo} alt="img" />
									</div>
								</div>
								<div className="single-testimonial testmonials2">
									<div className="testi-author">
										<img src={MariannaNascadeBacana} alt="img" />
									</div>
									<div className="review mb-30">
										<img src={ReviewIconOne} alt="img" />
									</div>
									<p>
									“Fiz um clareamento recentemente na clínica e estou encantada com o resultado.
									Para mim é um local completo, pois além de oferecer serviços de qualidade e acessíveis,
										os Profissionais envolvidos são competentes, 
									transmitem confiança e são super atenciosos. Super recomendo a Neoident!”

									</p>
									<div className="ta-info">
										<h6>Marianna Alves</h6>
										
									</div>
									<div className="qt-img">
										<img src={TestimonialIconTwo} alt="img" />
									</div>
								</div>

								<div className="single-testimonial testmonials2">
									<div className="testi-author">
										<img src={AnaCeciliaCorpodeLombriga} alt="img" />
									</div>
									<div className="review mb-30">
										<img src={ReviewIconOne} alt="img" />
									</div>
									<p>
									“Lugar, agradável, ótimo atendimento! Excelentes profissionais! Gostei da dentista,
									 eles olharam o dente do meu filho mesmo sem ele ter consulta marcada, a limpeza é bem completa.”

									</p>
									<div className="ta-info">
										<h6>Anna Cecília Ribeiro Vasconcelos</h6>
										
									</div>
									<div className="qt-img">
										<img src={TestimonialIconTwo} alt="img" />
									</div>
								</div>

								<div className="single-testimonial testmonials2">
									<div className="testi-author">
										<img src={AmandaGarotadePrograma} alt="img" />
									</div>
									<div className="review mb-30">
										<img src={ReviewIconOne} alt="img" />
									</div>
									<p>
									“Clínica bem organizada e limpa! São atenciosos para não deixar o cliente esperando!
                   Dentista Natália muito paciente e cautelosa, realizou uma limpeza nos mínimos detalhes 
									  e muito completa!”

									</p>
									<div className="ta-info">
										<h6>Amanda Ribeiro Lemos</h6>
										
									</div>
									<div className="qt-img">
										<img src={TestimonialIconTwo} alt="img" />
									</div>
								</div>

								<div className="single-testimonial testmonials2">
									<div className="testi-author">
										<img src={SuemioReumaticoAnemico} alt="img" />
									</div>
									<div className="review mb-30">
										<img src={ReviewIconOne} alt="img" />
									</div>
									<p>
									“Sou paciente há 8 anos....desde a época do Dr. Jorge.... Sempre fui muito bem atendido 
									desde a recepção aos profissionais nas áreas de periodontia.... ortodontia..... tratamento de excelência.”

									</p>
									<div className="ta-info">
										<h6>Suemio Castro</h6>
										
									</div>
									<div className="qt-img">
										<img src={TestimonialIconTwo} alt="img" />
									</div>
								</div>

								
							</Slider>
						</div>
					</div>
				</div>
			</section>

			<section
				id="pricing"
				className="pricing-area pt-120 pb-60 fix p-relative"
			>
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div
								className="section-title text-center mb-50 wow fadeInDown animated"
								data-animation="fadeInDown"
								data-delay=".4s"
							>
								<h2>Oferecemos</h2>
							</div>
						</div>
						<div className="col-lg-4 col-md-6">
							<div className="pricing-box pricing-box2 text-center mb-60" style={{padding: '56px'}}>
								<div className="pricing-head">
									<div className="icon">
										<img src={Iconslider} alt="img" />
									</div>
									<h3>Estética do sorriso</h3>

									<hr />
								</div>
								<div className="pricing-body mt-20 mb-30 text-left">
									<ul>
										<li>Clareamento dental</li>
										<li>Lente de contato de porcelana </li>
										<li>Lente de contato de resina</li>
										<li>Alinhamento do sorriso</li>
									</ul>
								</div>
								<div className="pricing-btn">
									<Link to="esteticadosorisso" className="btn ss-btn">
                                    Leia Mais
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6">
							<div className="pricing-box pricing-box2 active text-center mb-60" style={{padding: "62px"}}>
								<div className="pricing-head">
									<div className="icon">
										<img src={IconSlidertwo} alt="img" /> 
									</div>
									<h3>Implantes</h3>
									<div className="price-count">
										<h6>Perdi um dente e agora?</h6>
									</div>
									<hr />
								</div>
								<div className="pricing-body mt-20 mb-30 text-left">
									<ul>
										<li>Substitui um dente ou mais</li>
										<li>Dente fraturado</li>
										<li>Dente perdido</li>
										<li>Dentadura caindo </li>
										<li>Correção de falhas</li>
									</ul>
								</div>
								<div className="pricing-btn">
									<Link to="implantes" className="btn ss-btn">
									Leia Mais
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6">
							<div className="pricing-box pricing-box2 text-center mb-60" style={{padding: "54px"}} > 
								<div className="pricing-head">
									<div className="icon">
										<img src={IconSliderthree} alt="img" />
									</div>
									<h3>Ortodontia</h3>
									<div className="price-count">
										<h6>Tenho dentes tortos</h6>
									</div>
									<hr />
								</div>
								<div className="pricing-body mt-20 mb-30 text-left">
									<ul>
										<li>Aparelho invisível</li>
										<li>Aparelho auto ligado</li>
										<li>Aparelho fixo</li>
										<li>Aparelho metálico</li>
										<li>Aparelho convencional</li>
                                        <li>Aparelho ortopédico</li>
									</ul>
								</div>
								<div className="pricing-btn">
									<Link to="ortodontia" className="btn ss-btn">
										Leia Mais
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default Testimonialslider;
