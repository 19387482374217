import React from "react";
import Innerone from "../../assets/img/blog/logo-amil.png";
import Innertwo from "../../assets/img/blog/logo-caa.png";
import Innerthree from "../../assets/img/blog/logo-caixa.png";
import { Link } from "react-router-dom";
import Innerfour from "../../assets/img/blog/logo-gdf.png";
import Innerfive from "../../assets/img/blog/logo-plan.png";
import Ryancaradecu from "../../assets/img/blog/logo-golden.png";
import Luizamordemylifezinha from "../../assets/img/blog/logo-proasa.png";

function Blog() {
	return (
		<>
			<section
				id="blog"
				className="blog-area p-relative fix pt-90 pb-90"
				style={{ marginLeft: "50px" }}
			>
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-12">
							<div className="section-title center-align mb-50 text-center">
								<h2>Convênios</h2>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-3 col-md-6">
							<div
								className="single-post2 hover-zoomin mb-30 wow fadeInUp animated"
								data-animation="fadeInUp"
								data-delay=".4s"
							>
								<div className="blog-thumb2">
									<Link to="">
										<img src={Innerone} alt="img" style={{width:"57%"}} />
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div
								className="single-post2 mb-30 hover-zoomin wow fadeInUp animated"
								data-animation="fadeInUp"
								data-delay=".4s"
							>
								<div className="blog-thumb2">
									<Link to="">
										<img src={Innertwo} alt="img" style={{width:"57%"}} />
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div
								className="single-post2 mb-30 hover-zoomin wow fadeInUp animated"
								data-animation="fadeInUp"
								data-delay=".4s"
							>
								<div className="blog-thumb2">
									<Link to="">
										<img src={Innerthree} alt="img" style={{width:"57%"}} />
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div
								className="single-post2 mb-30 hover-zoomin wow fadeInUp animated"
								data-animation="fadeInUp"
								data-delay=".4s"
							>
								<div className="blog-thumb2">
									<Link to="">
										<img src={Innerfour} alt="img" style={{width:"57%"}} />
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div
								className="single-post2 mb-30 hover-zoomin wow fadeInUp animated"
								data-animation="fadeInUp"
								data-delay=".4s"
							>
								<div className="blog-thumb2">
									<Link to="">
										<img src={Innerfive} alt="img" />
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div
								className="single-post2 mb-30 hover-zoomin wow fadeInUp animated"
								data-animation="fadeInUp"
								data-delay=".4s"
							>
								<div className="blog-thumb2">
									<Link to="">
										<img src={Ryancaradecu} alt="img" />
									</Link>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div
								className="single-post2 mb-30 hover-zoomin wow fadeInUp animated"
								data-animation="fadeInUp"
								data-delay=".4s"
							>
								<div className="blog-thumb2">
									<Link to="">
										<img src={Luizamordemylifezinha} alt="img" />
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default Blog;
