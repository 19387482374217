import React from "react";
import Bredcm from "../Menhome/breadcrumb";
import Fotoperiodontia from '../../assets/img/features/clareamento_de_consultorio.png';
import About from "../Menabout/About";
import Appointment from "../Menhome/Appointment";
import Ourdr from "../Menhome/Ourdr";
import Faq from "../Menhome/Faqsection";
import Blog from "../Menhome/Blog";

function Main() {
	return (
		<>
			<Bredcm subtitle="Clareamento" title="Home" subtitledown="Clareamento" />

			<section className="about-area about-p pt-120 pb-120 p-relative fix">
				<div className="container">
					<div className="row justify-content-center align-items-center">
						<div className="col-lg-6 col-md-12 col-sm-12">
							<div
								className="s-about-img p-relative  wow fadeInLeft animated"
								data-animation="fadeInLeft"
								data-delay=".4s"
							>
								<img src={Fotoperiodontia} alt="img" />
								
							</div>
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12">
							<div
								className="about-content s-about-content  wow fadeInRight  animated"
								data-animation="fadeInRight"
								data-delay=".4s"
							>
								<div className="about-title second-title pb-25">
									<h5>Clareamento</h5>
								</div>
								<p>
								Quem não quer um sorriso bonito? Pelo menos entre os brasileiros, a vontade é unânime! 
								De acordo com pesquisas internacionais de mercado, o brasileiro é um dos povos mais vaidosos do mundo.
								 E essa vaidade faz sentido.
								 Porque de acordo com um estudo publicado no periódico alemão “Journal of Economic Psychology”,
								  o sorriso é capaz de transmitir confiança. Muito além da beleza, um sorriso simétrico,
								  com dentes brancos pode ajudar as pessoas a terem relações pessoais melhores.
								 E por que não profissionais também? Isso explica porque a procura pelo clareamento dental é tão grande por nossas bandas. Somos um povo que sorri muito!
								</p>
								
								<p>Mas os dentes escurecem, infelizmente. Isso é um fato. Com a idade, os dentes vão ficando cada vez mais amarelados e escuros, porque ocorre desgaste do esmalte tornando a dentina mais visível.
									 A dentina é o tecido que fica por baixo do esmalte. Ela vai ficando mais densa, o que torna os dentes mais escuros ao longo do tempo. Por isso o clareamento dental é bastante procurado entre adultos e idosos. Entretanto, 
									 hábitos de vida das pessoas também podem influenciar e muitas vezes acelerar o processo natural de escurecimento dos dentes. O consumo frequente de café,
									  açaí e outros alimentos que possuam grandes quantidades de corantes interfere na pigmentação dos dentes.
									 Assim como a higiene, fatores genéticos, o uso de tabaco ou de medicamentos.</p>
                   <p>São inúmeros os motivos e as indicações para o clareamento dental. 
										Portanto, se você está incomodado com a coloração dos seus dentes,
										 o ideal é marcar o quanto antes uma consulta com um dos profissionais da Unique Odontologia.</p>
									

							</div>
						</div>
					</div>
					<div className="slider-btn mt-20">
									<a  style={{left: "1px", background: "#1da551", marginLeft: "567px"}} target="blank" href="https://api.whatsapp.com/send?phone=5561996221835&text=Ol%C3%A1%20gostaria%20de%20agendar%20uma%20consulta%20de%20Implante%20dent%C3%A1rio%20na%20Cl%C3%ADnica%20Neoident!" className="btn ss-btn smoth-scroll">
										Agende sua consulta
									</a>
								</div>
				</div>
			</section>
		</>
	);
}

export default Main;
