import React from "react";
import Bredcm from "../Menhome/breadcrumb";
import Fotocirurgia from "../../assets/img/features/fotocirurgias.png";
import About from "../Menabout/About";
import Appointment from "../Menhome/Appointment";
import Ourdr from "../Menhome/Ourdr";
import Faq from "../Menhome/Faqsection";
import Blog from "../Menhome/Blog";

function Main() {
	return (
		<>
			<Bredcm subtitle="Cirurgias" title="Home" subtitledown="Cirurgias" />

			<section className="about-area about-p pt-120 pb-120 p-relative fix">
				<div className="container">
					<div className="row justify-content-center align-items-center">
						<div className="col-lg-6 col-md-12 col-sm-12">
							<div
								className="s-about-img p-relative  wow fadeInLeft animated"
								data-animation="fadeInLeft"
								data-delay=".4s"
							>
								<img src={Fotocirurgia} alt="img" />
								
							</div>
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12">
							<div
								className="about-content s-about-content  wow fadeInRight  animated"
								data-animation="fadeInRight"
								data-delay=".4s"
							>
								<div className="about-title second-title pb-25">
									<h2>CIRURGIAS</h2>
								</div>
								<p>
									Os tratamentos dentários nem sempre se resumem a procedimentos
									simples, como a remoção de tártaro ou cárie. Existem diversos
									casos em que é necessário realizar cirurgias odontológicas,
									seja para tratar um problema, seja com finalidades estéticas.
								</p>
								<p>
									São consideradas cirurgias odontológicas todas as intervenções
									realizadas de forma manual, ou com auxílio de instrumentos,
									para tratar traumatismos e doenças que afetam os tecidos
									bucais, a arcada dentária e a face do paciente.
								</p>
								<p>
									Esses procedimentos têm indicações e técnicas muito variadas,
									porque são recomendados para casos dos mais distintos. Para
									extrair um dente, por exemplo, fazemos uma cirurgia
									odontológica, também é um cirurgião dentista que realiza as
									cirurgias buco-maxilo-faciais, geralmente bem mais complexas.
									Por meio das cirurgias odontológicas, então:
								</p>
								<ul>
									<li>-Repomos tecidos perdidos;</li>
									<li>
										-Removemos tecidos comprometidos por um trauma ou doença;
									</li>
									<li>
										-Corrigimos alterações anatômicas congênitas, relacionadas
										ao desenvolvimento ou causadas por acidentes e doenças;
									</li>
									<li>
									- Reabilitamos dentes perdidos
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="slider-btn mt-20">
									<a  style={{left: "1px", background: "#1da551", marginLeft: "567px"}} target="blank" href="https://api.whatsapp.com/send?phone=5561996221835&text=Ol%C3%A1%20gostaria%20de%20agendar%20uma%20consulta%20de%20Implante%20dent%C3%A1rio%20na%20Cl%C3%ADnica%20Neoident!" className="btn ss-btn smoth-scroll">
										Agende sua consulta
									</a>
								</div>
				</div>
			</section>
		</>
	);
}

export default Main;
