import React from "react";
import FeaturesservicesTwo from "../../assets/img/features/about_img.png";
import { Link } from "react-router-dom";

function About() {
	return (
		<>
			<section className="about-area about-p pt-120 pb-120 p-relative fix">
				<div className="container">
					<div className="row justify-content-center align-items-center">
						<div className="col-lg-6 col-md-12 col-sm-12">
							<div
								className="s-about-img p-relative  wow fadeInLeft animated"
								data-animation="fadeInLeft"
								data-delay=".4s"
							>
								<img src={FeaturesservicesTwo} alt="img" />
								<div className="about-text second-about">
									<span>50</span>
									<p>Years of Experience</p>
								</div>
							</div>
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12">
							<div
								className="about-content s-about-content  wow fadeInRight  animated"
								data-animation="fadeInRight"
								data-delay=".4s"
							>
								<div className="about-title second-title pb-25">
									<h5>Sobre nós</h5>
									<h2>Quem somos?</h2>
								</div>
								<p>
									A clinica Neoident é uma clínica odontológica completa cujo
									diferencial é a entrega de serviços odontológicos simples e
									complexos de forma acessível aos pacientes.
								</p>
								<p>
									Paciente isto só é possível graças há anos de investimentos e
									em técnicas atualizadas, infraestrutura adequada e parcerias
									com empresas.
								</p>
								<p>
									Mas, acima de tudo, temos a honra de contar com uma equipe
									altamente qualificada que se dedica à Odontologia
									integralmente.
								</p>
								Nosso paciente é o foco central de tudo isto. Nada paga o
								sorriso de agradecimento de um paciente bem atendido.
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default About;
