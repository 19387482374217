import React from "react";
import Logo from "../../assets/img/logo/logo-footer.png";
import { Link } from "react-router-dom";

function Main() {
	return (
		<>
			<footer
				className="footer-bg footer-p pt-90"
				style={{ backgroundColor: "#c6a663" }}
			>
				<div className="footer-top pb-70">
					<div className="container">
						<div className="row justify-content-between">
							<div className="col-xl-4 col-lg-4 col-sm-6">
								<div className="footer-widget mb-30">
									<div className="f-widget-title">
										<h2>
											<img src={Logo} alt="logo" style={{borderRadius:"10px"}} />
										</h2>
									</div>
									<div className="footer-link">
										<p>Odontologia transformando sorrisos.</p>
										<div className="f-contact">
											<ul>
												<li>
													<i className="icon fal fa-clock" />
													<span>
														Segunda à quinta:
														<br />
														8H às 22H
													</span>
													<p style={{marginLeft:"54px"}}>
														Sexta:
														<br />
														8H às 18H
													</p>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-2 col-lg-2 col-sm-6">
								<div className="footer-widget mb-30">
									<div className="f-widget-title">
										<h2>Páginas</h2>
									</div>
									<div className="footer-link">
										<ul>
											<li>
												<Link to="/"> Home</Link>
											</li>
											<li>
											<a href="sobre">Quem somos</a>
											</li>
											<li>
											<a href="#service-details2">Especialidades</a>
											</li>
											<li>
											<a href="#pricing"> Planos</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col-xl-2 col-lg-2 col-sm-6">
								<div className="footer-widget mb-30">
									<div className="f-widget-title">
										<h2>Serviços</h2>
									</div>
									<div className="footer-link">
										<ul>
											<li>
												<Link to="cirurgias">Cirurgias</Link>
											</li>
											<li>
												<Link to="ortodontia">Ortodontia</Link>
											</li>
											<li>
												<Link to="lentesdecontato">Lentes de contato</Link>
											</li>
											<li>
												<Link to="clinicogeral">Clínico geral</Link>
											</li>
											<li>
												<Link to="odontopediatria">Atendimento a crianças</Link>
											</li>
											<li>
												<Link to="clareamento">Clareamento Dental</Link>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-3 col-sm-6">
								<div className="footer-widget mb-30">
									<div className="f-widget-title">
										<h2>Fale Conosco</h2>
									</div>
									<div className="f-contact">
										<ul>
											<li style={{marginLeft:"-15px"}}>
												<i className="icon fal fa-map-marker-check" />
												<a href="https://www.google.com/maps/place/Clínica+Neoident+Odontologia/@-15.8004461,-47.929748,17z/data=!4m6!3m5!1s0x935a30776b955555:0x755b708c94b1714c!8m2!3d-15.8004461!4d-47.9252419!16s%2Fg%2F11b6j7nk5c?entry=ttu">
												<span>
													CLSW 303 - BL A sala 115 - Ed Atlanta Center Avenida
													Comercial Sudoeste - BRASÍLIA - DF
												</span>
												</a>
											</li>
											<li style={{marginLeft:"-15px"}}>
												<i className="icon fal fa-phone" />
												<a href="https://api.whatsapp.com/send?phone=5561996221835&text=Ol%C3%A1!%20Visualizei%20o%20site%20e%20gostaria%20de%20agendar%20uma%20consulta.%0A"> 
												<span style={{display: "table-cell", paddingTop: "10px"}}>(61) 99622-1835</span>
												</a>
											</li>
											<li style={{marginLeft:"-15px"}}>
												<i className="icon fal fa-envelope" />
												<span>
													<br />
													<Link to="#" style={{display: "flex", marginTop: "-12px"}}> 
													clinicaneoidentodontologia@gmail.com
													</Link>
												</span>
											</li>
										</ul>
									</div>
								</div>     
							</div>
						</div>
					</div>
				</div>

				<div className="copyright-wrap">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-4">
								<div className="copy-text">Desenvolvido por DF Informática</div>
							</div>
							<div className="col-lg-4"></div>
							<div className="col-lg-4">
								<div className="footer-social">
									<a href="https://www.facebook.com/odontologianeoident/">
										<i className="fab fa-facebook-f" />
									</a>
									<a href="https://www.instagram.com/clinica_neoidentbrasilia/?hl=en">
										<i className="fab fa-instagram" />
									</a>
									
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
}

export default Main;
