import React from "react";
import Bredcm from "../Menhome/breadcrumb";
import Fotolente from "../../assets/img/features/lenteresina.jpg";
import Fotoprocedimento from "../../assets/img/features/_MG_7635.png";
import About from "../Menabout/About";
import Appointment from "../Menhome/Appointment";
import Ourdr from "../Menhome/Ourdr";
import Faq from "../Menhome/Faqsection";
import Blog from "../Menhome/Blog";

function Main() {
	return (
		<>
			<Bredcm subtitle="Estética do sorriso" title="Home" subtitledown="Estética do sorriso" />

			<section className="about-area about-p pt-120 pb-120 p-relative fix">
				<div className="container">
					<div className="row justify-content-center align-items-center">
						<div className="col-lg-6 col-md-12 col-sm-12">
							<div
								className="s-about-img p-relative  wow fadeInLeft animated"
								data-animation="fadeInLeft"
								data-delay=".4s"
							>
								
								<img src={Fotolente} alt="img" />
								<img src={Fotoprocedimento} alt="img" />
								

							
							</div>
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12">
							<div
								className="about-content s-about-content  wow fadeInRight  animated"
								data-animation="fadeInRight"
								data-delay=".4s"
							>
								<div className="about-title second-title pb-25">
									<h5>Para conquistar o sorriso perfeito, que tal se beneficiar da lente de contato dental?</h5>
								</div>
								<p>
								É ela que tem feito a cabeça dos famosos e de muita gente, 
								pois tem aplicação rápida e corrige aquelas falhas 
								e imperfeições dos nossos dentes que não gostaríamos de exibir.
								</p>
								<h5>Mas como funciona?</h5>
								<p>
								Elas são lâminas finas de porcelana ou de algum outro composto da cor do dente, 
								renovando a aparência do sorriso.
								</p>
								<p>
								Elas são lâminas finas de porcelana da cor do dente, renovando a aparência do sorriso.
								</p>
								<h5>A lente de contato é puramente estética? Elas podem prejudicar meus dentes?</h5>
									<p>Além de deixar o sorriso com os dentes brancos, a lente de contato dental também pode ser indicada
										 para casos específicos de correções na anatomia dos dentes.</p>
										 <p>Por exemplo: para pessoas com microdentes ou em formas não ideais, como em formato de cone. 
											Além disso, pode ser uma alternativa para quem tem dentes considerados muito escuros - 
											e para os quais o clareamento dental já não é uma alternativa</p>
										<p>Ah, e a lente de contato dental é diferente da faceta dental. 
												As facetas, em resumo, são mais grossas do que as lentes.</p>
								</div>
						</div>
					</div>
					<div className="slider-btn mt-20">
									<a  style={{left: "1px", background: "#1da551", marginLeft: "567px"}} target="blank" href="https://api.whatsapp.com/send?phone=5561996221835&text=Ol%C3%A1!%20Gostaria%20de%20agendar%20uma%20consulta%20de%20Est%C3%A9tica%20do%20Sorriso%20na%20Cli%CC%81nica%20Odontol%C3%B3gica%20Neoident" className="btn ss-btn smoth-scroll">
										Agende sua consulta
									</a>
								</div>
				</div>
			</section>
		</>
	);
}

export default Main;
