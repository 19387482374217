import React from "react";
import Iconone from "../../assets/img/icon/fe-icon01.png";
import IconFour from "../../assets/img/icon/fe-icon04.png";
import IconFive from "../../assets/img/icon/fe-icon05.png";
import IconSix from "../../assets/img/icon/fe-icon06.png";
import IconSeven from "../../assets/img/icon/fe-icon07.png";
import IconEight from "../../assets/img/icon/fe-icon08.png";
import Featuresservices from "../../assets/img/features/foto-dente.png";
import FeaturesservicesTw from "../../assets/img/features/about_img.png";
import { Link } from "react-router-dom";

function Ourservices() {
	return (
		<>
			<section
				id="service-details2"
				className="service-details-two pt-120 pb-105 p-relative"
			>
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-12">
							<div className="section-title center-align mb-50 text-center">
								<h2>O que oferecemos</h2>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 col-sm-12">
							<div className="about-content s-about-content">
								<ul className="sr-tw-ul text-right">
									<li>
										<div className="text">
											<Link to="cirurgias">
												<h4>Cirurgias</h4>
											</Link>
											<p>Extração de siso, implantes e enxertos;</p>
											<div className="pricing-btn">
												<Link
													to="cirurgias"
													className="btn ss-btn"
													style={{ fontSize: "10px", left: "-30px" }}
												>
													Leia mais
												</Link>
											</div>
										</div>
										<div className="icon-right">
											<img src={Iconone} alt="icon01" />
										</div>
									</li>
									<li style={{ padding: "45px" }}>
										<div className="text" style={{ marginLeft: " 9px" }}>
											<Link to="ortodontia">
												<h4>Ortodontia</h4>{" "}
											</Link>
											<p>Alinhamento dos dentes e ossos maxilares.</p>
											<div className="pricing-btn">
												<Link
													to="ortodontia"
													className="btn ss-btn"
													style={{ fontSize: "10px", left: "-29px" }}
												>
													Leia mais
												</Link>
											</div>
										</div>
										<div className="icon-right">
											<img src={IconFive} alt="icon01" />
										</div>
									</li>
									<li>
										<div className="text">
											<Link to="lentesdecontato" style={{ fontSize: "10px" }}>
												<h4>Lentes de contato dental</h4>
											</Link>
											<p>Dentes mais alinhados e naturais.</p>
											<div className="pricing-btn">
												<Link
													to="lentesdecontato"
													className="btn ss-btn"
													style={{ fontSize: "10px", left: "-30px" }}
												>
													Leia mais
												</Link>
											</div>
										</div>
										<div className="icon-right">
											<img src={IconSeven} alt="icon01" />
										</div>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-4 col-md-12 col-sm-12 text-center d-none d-lg-block">
							<div className="sd-img">
								<img
									src={Featuresservices}
									alt="img"
									style={{ maxWidth: "" }}
								/>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 col-sm-12">
							<div className="about-content s-about-content">
								<ul className="sr-tw-ul">
									<li>
										<div className="icon">
											<img src={IconFour} alt="icon01" />
										</div>
										<div className="text pt-10">
											<Link to="clinicogeral">
												<h4>Clínico geral</h4>{" "}
											</Link>
											<p>
												Restaurações, prevenção/limpeza, aplicação flúor e
												profilaxia.
											</p>
											<div className="pricing-btn">
												<Link
													to="clinicogeral"
													className="btn ss-btn servicus"
													style={{ fontSize: "10px", left: "50px" }}    
												>
													Leia mais
												</Link>
											</div>
										</div>
									</li>
									<li>
										<div className="icon">
											<img src={IconSix} alt="icon01" />
										</div>
										<div className="text pt-10" style={{marginLeft:"-25px"}}>
											<Link to="odontopediatria">
												<h4>Atendimento a crianças</h4>
											</Link>
											<p>A área que cuida da saúde bucal das crianças.</p>
											<div className="pricing-btn">
												<Link
													to="odontopediatria"
													className="btn ss-btn crionças"
													style={{ fontSize: "10px", left: "53px" }}
												>
													Leia mais
												</Link>
											</div>
										</div>
									</li>
									<li style={{ padding: "45px" }}>
										<div className="icon">
											<img src={IconEight} alt="icon01" />
										</div>
										<div className="text pt-10" style={{ marginLeft: "-15%" }}>
											<Link to="Clareamento">
												<h4>Clareamento Dental</h4>
											</Link>
											<p>Dentes claros e iluminados.</p>
											<div className="pricing-btn">
												<Link to="clareamento" className="btn ss-btn clareamento" style={{ fontSize: "10px", left: "39px" }}>
													Leia mais
												</Link>
											</div>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="about-area about-p pb-120 p-relative fix">
				<div className="container">
					<div className="row justify-content-center align-items-center">
						<div className="col-lg-6 col-md-12 col-sm-12">
							<div
								className="s-about-img p-relative  wow fadeInLeft animated"
								data-animation="fadeInLeft"
								data-delay=".4s"
							>
								<img className="img-sobre" src={FeaturesservicesTw} alt="img" />
							</div>
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12">
							<div
								className="about-content s-about-content  wow fadeInRight  animated"
								data-animation="fadeInRight"
								data-delay=".4s"
							>
								<div className="about-title second-title pb-25">
									<h2>Conheça nossa história</h2>
								</div>
								<p>
									A clinica Neoident é uma clínica odontológica completa cujo
									diferencial é a entrega de serviços odontológicos simples e
									complexos de forma acessível aos pacientes com empatia e
									cordialidade. Buscando sempre o melhor atendimento e
									pontualidade.
								</p>
								<p>
									{" "}
									Nosso paciente é o foco central de tudo isto. Nada paga o
									sorriso de agradecimento de um paciente bem atendido.
								</p>
								<p>
									Estamos há 22 anos construindo nossa história com técnicas
									atualizadas, infraestrutura adequada, parcerias com empresas.
								</p>
								<p>
									Mas, acima de tudo, temos a honra de contar com uma equipe
									altamente qualificada que se dedica à Odontologia
									integralmente respeitando com ética e humanização.
								</p>
								<p>
									Venha nos conhecer e desfrute do melhor acompanhamento
									odontológico que você encontrará em toda a região! Não perca
									mais tempo e cuide de seu sorriso!
								</p>
								<div className="slider-btn mt-20">
									<a
										style={{ left: "180px", background: "#1da551"}}
										target="blank"
										href="https://api.whatsapp.com/send?phone=5561996221835&text=Olá!%20Vi%20o%20trabalho%20de%20vocês%20pelo%20site%20e%20gostaria%20de%20agendar%20uma%20consulta%20na%20Clínica%20Neoident."
										className="btn ss-btn smoth-scroll"
									>
										Agende sua consulta
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default Ourservices;
