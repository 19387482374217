import React from "react";
import Bredcm from "../Menhome/breadcrumb";
import Fotoortodontia from "../../assets/img/features/foto-ortodontia1.png";
import Fotoortodontia2 from "../../assets/img/features/foto-ortodontia2.png";
import Fotoortodontia3 from "../../assets/img/features/foto-ortodontia3.png";
import Fotoortodontia4 from "../../assets/img/features/foto-ortodontia4.png";
import Fotoortodontia5 from "../../assets/img/features/foto-ortodontia5.png";
import Fotoortodontia6 from "../../assets/img/features/foto-ortodontia6.png";
import Fotoortodontia7 from "../../assets/img/features/foto-ortodontia7.png";
import About from "../Menabout/About";
import Appointment from "../Menhome/Appointment";
import Ourdr from "../Menhome/Ourdr";
import Faq from "../Menhome/Faqsection";
import Blog from "../Menhome/Blog";

function Main() {
	return (
		<>
			<Bredcm
				subtitle="Ortodontia"
				title="Home"
				subtitledown=" Correção dos Dentes tortos "
			/>

			<section className="about-area about-p pt-120 pb-120 p-relative fix">
				<div className="container">
					<div className="row justify-content-center align-items-center">
						<div className="col-lg-6 col-md-12 col-sm-12">
							<div
								className="s-about-img p-relative  wow fadeInLeft animated"
								data-animation="fadeInLeft"
								data-delay=".4s"
								style={{ maxWidth: "80%" }}
							>
								<img src={Fotoortodontia} alt="img" />
								<img src={Fotoortodontia2} alt="img" />
								<img src={Fotoortodontia3} alt="img" />
								<img src={Fotoortodontia4} alt="img" />
								<img src={Fotoortodontia5} alt="img" />
								<img src={Fotoortodontia6} alt="img" />
								<img src={Fotoortodontia7} alt="img" />
							</div>
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12">
							<div
								className="about-content s-about-content  wow fadeInRight  animated"
								data-animation="fadeInRight"
								data-delay=".4s"
							>
								<div className="about-title second-title pb-25">
									<h5>Você tem dentes tortos ou abertos?</h5>
								</div>
								<p>
									Quem não quer ficar com o sorriso dos artistas e voltar a
									sorrir para a vida? O desejo de ter um sorriso harmônico é
									bastante comum, mas o que nem todos os pacientes conhecem são
									os tratamentos para o dente torto. Pensando nisso, a seguir
									explica o que fazer para tratar disfunções no crescimento e no
									desenvolvimento dentário.
								</p>
								<p>
									Apresentamos as possíveis causas de dente torto e soluções
									adequadas, a exemplo de aparelho metálico, lente de contato
									dental e o alinhador invisível, que acabam com esse problema,
									melhoram a estética facial e deixam o sorriso bem alinhado
								</p>
								<h5> Por que dentes tortos podem impactar a autoestima?</h5>
								<p>
									A dentição cumpre inúmeras funções na boca (mastigação, fala,
									respiração etc.) e desempenha, também, um papel importante
									para manter o sorriso harmônico. No entanto, dentes tortos,
									com disfunção no crescimento e no desenvolvimento dentário,
									acarretam impactos negativos para a estética do rosto,
									motivando problema de autoestima, medo de sorrir em público e
									dificuldade de socialização.
								</p>
								<h5>Falta de espaço</h5>
								<p>
									A falta de espaço dental é uma das principais causas do
									problema, já que a dentição não tem como se desenvolver
									plenamente na arcada dentária, pressionando outros dentes.
									Nesse caso, geralmente o aparelho ortodôntico é indicado para
									ampliar o espaçamento e reposicionar, com uma técnica
									adequada, cada dente.
								</p>
								<h5>Respiração pela boca</h5>
								<p>
									Respirar pela boca, prática comum nos primeiros anos de vida,
									pode ocasionar, gradativamente, alteração nas arcadas
									dentárias e no formato dos ossos maxilares. O processo ocorre
									devido à abertura prolongada da cavidade bucal, principalmente
									à noite, durante o sono, modificando o posicionamento da
									língua e o alinhamento dental{" "}
								</p>
								<h5>Nascimento dos sisos</h5>
								<p>
									Com a evolução da espécie humana, as arcadas dentárias se
									ajustaram a novos comportamentos alimentares, sendo essa
									mudança evolutiva e genética ainda mais visível em pacientes
									que não comportam os sisos, os últimos dentes a nascer na
									cavidade bucal. A nova dentição acaba empurrando os demais
									dentes, afetando o alinhamento dental em efeito dominó.
								</p>
								<h5>Falta de contenção</h5>
								<p>
									O aparelho de contenção é indicado, geralmente, para quem
									concluiu o tratamento com dispositivos ortodônticos, mas ainda
									sofre com alto índice de movimentação dentária. Sem o
									equipamento, os dentes podem voltar a uma posição inadequada,
									afetando o sorriso harmônico.
								</p>
								<h5>Quais as soluções para corrigir os dentes tortos?</h5>
								<ul>
									<li>Alinhador invisível</li>
									<li>Aparelho Otopédico</li>
									<li>Aparelho autoligado</li>
									<li>Aparelho fixo estético</li>
									<li>Aparelho fixo de metal</li>
									<li>Aparelho lingual</li>
									<li>Aparelho móvel </li>
									<li>Aparelho extrabucal</li>
									<li>Lente de contato dental</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="slider-btn mt-20">
									<a  style={{left: "1px", background: "#1da551", marginLeft: "567px"}} target="blank" href="https://api.whatsapp.com/send?phone=5561996221835&text=Ol%C3%A1!%20Gostaria%20de%20agendar%20uma%20consulta%20de%20Ortondotia%20na%20Cli%CC%81nica%20Odontol%C3%B3gica%20Neoident" className="btn ss-btn smoth-scroll">
										Agende sua consulta
									</a>
								</div>
				</div>
			</section>
		</>
	);
}

export default Main;
