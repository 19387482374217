import React , { useState } from 'react'
import Faqimg from '../../assets/img/bg/faq-img.png'


function Faqsection() {
    const [Faq, setFaq] = useState(0);

    const Drop = [
        {
            Que: "Como é feito o tratamento?",
            Answer: "As lentes de contato dentais são lâminas muito finas que podem ser feitas de resina ou de porcelana. Elas são colocadas sob os dentes na região da frente devolvendo o alinhamento, cor e estética harmonizando o sorriso. Se forem feitas de resina são colocadas, em geral, em uma única sessão, com duração de menos de cinco horas."
        },
        {
            Que: "Como posso cuidar corretamente do meu aparelho?",
            Answer: " A partir do momento que se coloca aparelho fixo, o paciente não deve mais utilizar os dentes anteriores (da frente), para cortar alimentos, tais como: maçã, cenoura, sanduíches mais consistentes, carnes, balas, etc. Evite alimentos pegajosos (chicletes, balas de goma, caramelo, etc.)"
        },
        {
            Que: " Higiene bucal",
            Answer: " Para ter um sorriso bonito e saudável, é preciso: Escovar os dentes todos os dias, após cada refeição e também uma última vez antes de dormir, utilizando uma escova de dente de tamanho adequado, com cerdas macias e creme dental com flúor, e claro o fio dental para higienizar entre um dente e outro."
        },
        {
            Que: " O clareamento dental enfraquece os dentes?",
            Answer: " O clareamento dental pode deixar os dentes mais sensíveis por um tempo, mas não enfraquecidos. O ideal é conversar com o dentista e deixar a boca totalmente saudável para o clareamento. Depois desse procedimento, é importante evitar alguns alimentos para que o dente não escureça, mas fique tranquilo: seus dentes continuarão fortes e agora mais bonitos."
        }
    ]

    return (
        <>
            <section className="faq-area fix" style={{ backgroundColor: "#fff" }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="section-title mb-50">
                                <h5>FAQ</h5>
                                <h2>Perguntas Frequentes</h2>
                            </div>
                            <div className="faq-wrap">
                                <div className="accordion" id="accordionExample">
                               { Drop.map((items, index)=> (
                                    <div className="card" key={index}>
                                        <div className="card-header" id="headingThree">
                                            <h2 className="mb-0">
                                                <button className={`faq-btn ${Faq !== index && "collapsed"}`} type="button" data-bs-toggle="collapse" onClick={ ()=> {setFaq(index)}} data-bs-target="#collapseThree"  key={items}>
                                                    {items.Que}
                                                </button>
                                            </h2>
                                        </div>
                                        {Faq === index &&
                                            <div id="collapseThree" className="collapse show" data-bs-parent="#accordionExample" key={items}>
                                                <div className="card-body">
                                                {items.Answer}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="faq-img text-right">
                                <img src={Faqimg} alt="img" className="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>

  

        
    )
}

export default Faqsection