import React from "react";
import Bredcm from "../Menhome/breadcrumb";
import Fotoclinicogeral from "../../assets/img/features/fotoclinicogeral.png"
import About from "../Menabout/About";
import Appointment from "../Menhome/Appointment";
import Ourdr from "../Menhome/Ourdr";
import Faq from "../Menhome/Faqsection";
import Blog from "../Menhome/Blog";

function Main() {
	return (
		<>
			<Bredcm subtitle="Clinico geral" title="Home" subtitledown="CLinico geral" />

			<section className="about-area about-p pt-120 pb-120 p-relative fix">
				<div className="container">
					<div className="row justify-content-center align-items-center">
						<div className="col-lg-6 col-md-12 col-sm-12">
							<div
								className="s-about-img p-relative  wow fadeInLeft animated"
								data-animation="fadeInLeft"
								data-delay=".4s"
							>
								<img src={Fotoclinicogeral} alt="img" />
								
							</div>
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12">
							<div
								className="about-content s-about-content  wow fadeInRight  animated"
								data-animation="fadeInRight"
								data-delay=".4s"
							>
								<div className="about-title second-title pb-25">
									<h5>CLinico geral</h5>
								</div>
								<p>
								O dentista Clínico geral tem o primeiro contato com o paciente assim que ele procura uma clínica ou consultório odontológico. Nessa etapa inicial o dentista clínico geral vai entender o quadro de saúde do paciente, identificar a presença de cáries, problemas na gengiva, necessidades estéticas, além de chegar todo o histórico de tratamentos odontológicos anteriores.
								 Depois de analisar todo o quadro atual do paciente e seus históricos, o dentista clínico geral pode indicar o tratamento mais adequado para cada paciente,
								 inclusive direcionando para um especialista quando necessário.
								</p>
								<h3>Profilaxia </h3>
								<p>A profilaxia dentária consiste em uma limpeza que deve ser realizada sempre por um dentista especializado e capacitado na área.
									 Ao longo do procedimento, ele vai promover a remoção da placa bacteriana,
									 assim como do tártaro e de manchas superficiais, por meio do polimento e de uma raspagem.</p>
									 <h3>Restauração </h3>
								<p>Muito comuns nos casos onde o paciente apresenta cáries, a restauração tem como objetivo fazer com que um dente afetado volte a sua forma natural. Para isso o dentista clínico geral faz a remoção das partes comprometidas, limpa toda a região afetada, deixando o espaço pronto para ser restaurado.
									 Esse restauro é feito com o preenchimento da cavidade formada pela cárie com materiais diversos como ouro, amálgama e resina.
									  A restauração é realizada no próprio consultório do dentista clínico geral,
									 e o paciente pode retomar seus hábitos e atividades imediatamente após o procedimento.</p>
									 <h3>Selante </h3>
								<p>Esse tratamento é altamente recomendado tanto para crianças quanto adultos de todas as idades que apresentam alto risco de desenvolver cáries.
									 A aplicação da substância selante cria uma espécie de barreira protetora nos dentes que torna a higiene bucal e o controle da placa bacteriana muito mais fáceis, principalmente nos dentes posteriores.
									 Como são utilizados para triturar os alimentos, esses dentes possuem fissuras e onde ficam depositados restos de alimentos invisíveis a olho nu que não são alcançados pelas escovas.</p>
							</div>
						</div>
					</div>
					<div className="slider-btn mt-20">
									<a  style={{left: "1px", background: "#1da551", marginLeft: "567px"}} target="blank" href="https://api.whatsapp.com/send?phone=5561996221835&text=Ol%C3%A1%20gostaria%20de%20agendar%20uma%20consulta%20de%20Implante%20dent%C3%A1rio%20na%20Cl%C3%ADnica%20Neoident!" className="btn ss-btn smoth-scroll">
										Agende sua consulta
									</a>
								</div>
				</div>
			</section>
		</>
	);
}

export default Main;
