import React from 'react'
import SliderOne from '../../assets/img/slider/slider_bg.png'
import SliderTwo from '../../assets/img/slider/banner-aliment-icon-1.png'
import { Link } from 'react-router-dom'


function Main() {
  return (
    <>
        
            <div className="modal fade bs-example-modal-lg search-bg popup1" tabIndex={-1} role="dialog" >
                <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content search-popup">
                    <div className="text-center">
                    <Link to="#" className="close2" data-dismiss="modal" aria-label="Close" >
                        × close
                    </Link>
                    </div>
                    <div className="row search-outer">
                    <div className="col-md-11">
                        <input type="text" placeholder="Search for products..." />
                    </div>
                    <div className="col-md-1 text-right">
                        <Link to="#">
                        <i className="fa fa-search" aria-hidden="true" />
                        </Link>
                    </div>
                    </div>
                </div>
                </div>
            </div>
 
            <section id="home" className="slider-area fix p-relative">
                <div className="slider-active">
                <div className="single-slider slider-bg d-flex align-items-center" style={{backgroundImage: "url(img/slider/slider_bg.png)", backgroundSize: "cover"}}>
                   <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-7 col-md-7">
                        <div className="slider-content s-slider-content mt-20">
                            <h5 data-animation="fadeInUp" data-delay=".4s">
                            Bem-Vindo à Neoident
                            </h5>
                            <h2 data-animation="fadeInUp" data-delay=".4s">
                            Tudo que você precisa em um só lugar há 22 anos transformando sorrisos.
                            </h2>
                            <p data-animation="fadeInUp" data-delay=".6s">
                           Odontologia Especializada
                            </p>
                            <div className="subricbe" data-animation="fadeInDown" data-delay=".4s" >
                            </div>
                        </div>
                        </div>
                        
                        <div className="col-lg-5 col-md-5 p-relative">
                        <div className="banner-img">
                            <img src={SliderOne} alt="banner" style={{borderRadius:"25px"}} />
                        </div>
                       </div>
                       <div className="slider-btn mt-20">
									<a  style={{left: "1px", marginTop: "-195px", background: "#1da551"}} target="blank" href="https://api.whatsapp.com/send?phone=5561996221835&text=Ol%C3%A1!%20Vi%20o%20trabalho%20de%20voc%C3%AAs%20pelo%20site%20e%20gostaria%20de%20agendar%20uma%20consulta%20na%20Cli%CC%81nica%20Neoident." className="btn ss-btn smoth-scroll">
										Agende sua consulta
									</a>
								</div>
                    </div>
                   </div>
                </div>
                </div>
            </section>
    
    </>
  )
}

export default Main