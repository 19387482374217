import React from "react";
import Bredcm from "../Menhome/breadcrumb";
import Fotolentes from "../../assets/img/features/fotoclareamento.png";
import About from "../Menabout/About";
import Appointment from "../Menhome/Appointment";
import Ourdr from "../Menhome/Ourdr";
import Faq from "../Menhome/Faqsection";
import Blog from "../Menhome/Blog";

function Main() {
	return (
		<>
			<Bredcm subtitle="Lentes de contato" title="Home" subtitledown="Lentes de contato" />

			<section className="about-area about-p pt-120 pb-120 p-relative fix">
				<div className="container">
					<div className="row justify-content-center align-items-center">
						<div className="col-lg-6 col-md-12 col-sm-12">
							<div
								className="s-about-img p-relative  wow fadeInLeft animated"
								data-animation="fadeInLeft"
								data-delay=".4s"
							>
								<img src={Fotolentes} alt="img" />
								
							</div>
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12">
							<div
								className="about-content s-about-content  wow fadeInRight  animated"
								data-animation="fadeInRight"
								data-delay=".4s"
							>
								<div className="about-title second-title pb-25">
									<h2>CONQUISTE O SORRISO QUE VOCÊ TANTO DESEJA</h2>
								</div>
								<p>
								O sorriso de "capa de revista" já é possível através da Odontologia estética.
								</p>
								<p>
								Você já deve ter visto milhares de outdoors e capas de revistas com modelos exibindo um sorriso perfeito, né? 
								Hoje em dia é possível, sim, ter um sorriso de estrela de cinema! Tudo graças à odontologia estética,
								 área que trabalha de forma integrada com outras especialidades e profissionais para transformar sorrisos,
								 modificar cores, formatos e deixá-los com uma aparência ainda mais bonita.
								</p>
								<p>
								Sorriso e autoestima andam juntos
								</p>
							<p>Sorriso bonito tem tudo a ver com uma autoestima mais elevada, relações pessoais bem-sucedidas e bem-estar.</p>
							<p>"As pessoas querem dentes cada vez mais brancos e um sorriso perfeito. As orientações do dentista
								 e a divulgação dos cuidados de escovação,
								 bochechos e uso de fio dental junto com o flúor, melhorou a relação dos pacientes com a saúde bucal.</p>
								 <h3>O que a odontologia estética proporciona aos pacientes?</h3>
								 <p>Essa área da odontologia está cada vez mais desenvolvendo técnicas
									 e materiais mais seguros e resistentes para o conforto e satisfação dos que procuram o atendimento. </p>
									 <p>Hoje em dia, procura-se muito mais o dentista querendo melhorar sua estética bucal e
										 facial do que por motivos dolorosos. Algumas das possibilidades oferecidas por essa área.
										  "Clareamento dental, lentes de contato dentais,
										 facetas laminadas, coroas de porcelana sem desgaste , reforços dentários, entre outros".</p>
										 <h3>Em que casos a odontologia estética é indicada?</h3>
				 <p>A estética do sorriso, entretanto, não está somente ligada ao processo de elevar a
			   autoestima e harmonizar o sorriso do paciente, ela ajuda também a melhorar a funcionalidade da arcada dentária.
				"A odontologia estética deve ser aplicada sempre que houver um prejuízo na aparência dos dentes e do rosto. </p>

							</div>
						</div>
					</div>
					<div className="slider-btn mt-20">
									<a  style={{left: "1px", background: "#1da551", marginLeft: "567px"}} target="blank" href="https://api.whatsapp.com/send?phone=5561996221835&text=Ol%C3%A1%20gostaria%20de%20agendar%20uma%20consulta%20de%20Implante%20dent%C3%A1rio%20na%20Cl%C3%ADnica%20Neoident!" className="btn ss-btn smoth-scroll">
										Agende sua consulta
									</a>
								</div>
				</div>
			</section>
		</>
	);
}

export default Main;
