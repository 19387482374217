import React from 'react'
import GallerySec from '../PagesMen/Gallery/Main'

function Gallarymen() {
  return (
    <>
        <GallerySec/>
    </>
  )
}

export default Gallarymen